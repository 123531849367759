import React from "react";
import {
  SEO,
  PageLayout,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class HiringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <PageLayout className={"hiring-page"} hero={null}>
          <Grid stackable={true} columns={1} centered={true}>
            <Grid.Column textAlign={"left"} width={10}>
              <Header as={"h1"} textAlign={"center"}>
                Join our Team
              </Header>
              <Header textAlign={"center"} as={"h3"}>
                If you're interested in joining our team please submit your
                information below and we'll get in touch with you.
              </Header>
              <ContactForm
                constructMessage={createDefaultMessageFromState}
                fields={[
                  {
                    label: "name",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "address",
                    placeholder: "Address",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "birthdate",
                    placeholder: "Birthdate",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    displayLabel: "Previous Work Experience",
                    label: "workExperience",
                    placeholder: "Previous Work Experience",
                    type: "input",
                    required: true,
                  },
                  {
                    displayLabel: "Phone number of current or last employer",
                    label: "phoneNumberEmployer",
                    placeholder: "Phone number of current or last employer",
                    type: "input",
                    required: true,
                  },
                  {
                    displayLabel: "Reason for leaving previous employer",
                    label: "reasonOfLeaving",
                    placeholder: "Reason for leaving previous employer",
                    type: "textarea",
                    required: true,
                  },
                  {
                    label: "dateFirstAVailable",
                    displayLabel: "Date first available",
                    placeholder: "Date first available",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    label: "hoursAvailable",
                    displayLabel: "Hours Available",
                    type: "select",
                    required: true,
                    options: [
                      { label: "AM Shifts", value: "AM Shifts" },
                      { label: "PM Shifts", value: "PM Shifts" },
                      { label: "No preference", value: "No Preference" },
                    ],
                  },
                  {
                    label: "daysAvailable",
                    placeholder: "Days Available",
                    type: "multiSelect",
                    required: true,
                    options: [
                      { label: "Monday", value: "Monday" },
                      { label: "Tuesday", value: "Tuesday" },
                      { label: "Wednesday", value: "Wednesday" },
                      { label: "Thursday", value: "Thursday" },
                      { label: "Friday", value: "Friday" },
                      { label: "Saturday", value: "Saturday" },
                      { label: "Sunday", value: "Sunday" },
                    ],
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Hiring" }) {
      title
    }
  }
`;
